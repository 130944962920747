<template>
  <div>
    <div class="contain1" >
      <!-- v-for="(item, index) in vipList" -->
      <div
        :class="vip == item.id ? 'vip_item_active' : 'vip_item'"
        @click="changeVIP(item.id)"
        v-for="(item, index) in vipList" :key="index"
      >
        <div class="vip_item_top">
          <div class="vip_item_top_left">{{ item.name }}</div>
          <img src="@/assets/image/icon30.png" alt="" />            
        </div>
        <div class="line"></div>
        <div class="price">
          <div class="price1">
            <span>￥</span>
            <span>{{ item.price||0 }}</span>
            <span>元</span>
          </div>
          <div class="price2">￥ {{ item.old_price||0 }}</div>
        </div>
        <div class="line"></div>
        <div class="bottom">
          <img src="@/assets/image/icon31.png" alt="" />文档购买折扣：{{
            ((item.discount || 0)).toFixed(1)
          }}折
        </div>
      </div>
      <!-- <div
        :class="vip == 1 ? 'vip_item_active' : 'vip_item'"
        @click="changeVIP(1)"
      >
        <div class="vip_item_top">
          <div class="vip_item_top_left">季卡VIP</div>
          <img src="@/assets/image/icon30.png" alt="" />
        </div>
        <div class="line"></div>
        <div class="price">
          <div class="price1">
            <span>￥</span>
            <span>{{
              ((settings.vip.quarter_price || 0) / 100).toFixed(2)
            }}</span>
            <span>元</span>
          </div>
          <div class="price2">
            ￥{{
              ((settings.vip.quarter_original_price || 0) / 100).toFixed(2)
            }}
          </div>
        </div>
        <div class="line"></div>
        <div class="bottom">
          <img src="@/assets/image/icon31.png" alt="" />文档购买折扣：{{
            ((settings.vip.quarter_document_discount || 0) / 10).toFixed(1)
          }}折
        </div>
      </div>
      <div
        :class="vip == 2 ? 'vip_item_active' : 'vip_item'"
        @click="changeVIP(2)"
      >
        <div class="vip_item_top">
          <div class="vip_item_top_left">月卡VIP</div>
          <img src="@/assets/image/icon30.png" alt="" />
        </div>
        <div class="line"></div>
        <div class="price">
          <div class="price1">
            <span>￥</span>
            <span>{{
              ((settings.vip.month_price || 0) / 100).toFixed(2)
            }}</span>
            <span>元</span>
          </div>
          <div class="price2">
            ￥{{ ((settings.vip.month_original_price || 0) / 100).toFixed(2) }}
          </div>
        </div>
        <div class="line"></div>
        <div class="bottom">
          <img src="@/assets/image/icon31.png" alt="" />文档购买折扣：{{
            ((settings.vip.month_document_discount || 0) / 10).toFixed(1)
          }}折
        </div>
      </div> -->
    </div>
    <div class="contain2">
      <div class="h1">权益</div>
      <div class="line"></div>
      <div class="contain2_list">
        <div class="item">
          <img src="@/assets/image/icon32.png" alt="" />
          <div>
            <span>VIP文档</span>
            <span>专属免费下载</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/icon33.png" alt="" />
          <div>
            <span>身份标识</span>
            <span>头像标记，彰显尊贵</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/icon34.png" alt="" />
          <div>
            <span>文档折扣</span>
            <span>非VIP文档，享折扣优惠</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/icon35.png" alt="" />
          <div>
            <span>去广告</span>
            <span>清爽体验，不受广告干扰</span>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        width: 1400px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 20px;
      "
    >
      <div></div>
      <div></div>
      <div class="btn" @click="go">
        <img src="@/assets/image/icon36.png" alt="" />立即购买
      </div>
    </div>
  </div>
</template>

<script>
import { get, postJSON } from "/utils/request";
export default {
  data() {
    return {
      vipList: [
        {
          id: 1,
          name: "年卡VIP",
          price1: 100, //原价
          price2: 100, //现价
          discount: "1",
        },
        {
          id: 2,
          name: "季卡VIP",
          price1: 100, //原价
          price2: 100, //现价
          discount: "1",
        },
        {
          id: 3,
          name: "月卡VIP",
          price1: 100, //原价
          price2: 100, //现价
          discount: "1",
        },
      ],
      vip: 1, //1月卡 2季度 3年
      settings: {},
    };
  },
  mounted() {
    this.getSetting();
    document.body.style.backgroundColor = "#f8fff9";
  },
  methods: {
    changeVIP(id) {
      this.vip = id;
    },
    go() {
      let orderTypeBuyVIP = 2; // 购买VIP
      let token = localStorage.getItem("token");
      if (!token) {
        this.$message.warning("请先登录");
        this.$router.replace("/");
        return;
      }

      postJSON("/order", {
        order_type: orderTypeBuyVIP,
        product_id: this.vip,
      }).then((res) => {
        console.log(res, "----sdfsdf sdf sd -");
        // data: "af55a359-dc4e-11ee-94db-0242ac110007"
        if (res.message == "success") {
          this.$router.push({
            name: "payment",
            query: {
              order_no: res.data,
            },
          });
        } else {
          this.$message.error(res.message);
        }
      });
      // this.$router.push("/payment");// 跳转到支付页面
    },
    getSetting() {
      // 后台配置
      get("/config/vip", {}).then((res) => {
        console.log(res);
        this.vipList = res.data ?? [];
      });
    },
  },
};
</script>

<style scoped lang="scss">
.contain1 {
  width: 1400px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  .vip_item {
    width: 454px;
    height: 269px;
    background: #ffffff;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    .vip_item_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
      margin-bottom: 19px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .line {
      height: 1px;
      background: #f4f4f4;
    }
    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      .price1 {
        span:nth-child(1) {
          font-size: 29px;
          font-family: DIN Alternate, DIN Alternate-Bold;
          font-weight: 700;
          color: #333333;
          letter-spacing: 1.45px;
        }
        span:nth-child(2) {
          font-size: 53px;
          font-family: DIN Alternate, DIN Alternate-Bold;
          font-weight: 700;
          color: #333333;
          letter-spacing: 1.45px;
        }
        span:nth-child(3) {
          font-size: 29px;
          font-family: DIN Alternate, DIN Alternate-Bold;
          font-weight: 700;
          color: #333333;
          letter-spacing: 1.45px;
        }
      }
      .price2 {
        font-size: 26px;
        font-family: DIN, DIN-Regular;
        font-weight: 400;
        text-decoration: line-through;
        color: #999999;
        letter-spacing: 1.3px;
      }
    }
    .bottom {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      letter-spacing: 0.7px;
      margin: 16px 0 0;
      img {
        width: 9px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }
  .vip_item_active {
    width: 454px;
    height: 269px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 3px 6px 0px rgba(35, 220, 90, 0.54);
    cursor: pointer;

    .vip_item_top {
      height: 65px;
      background: linear-gradient(90deg, #23c48c, #33da61);
      border-radius: 6px 6px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #ffffff;
      letter-spacing: 0.9px;
      padding: 0 20px;
      box-sizing: border-box;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .line {
      height: 1px;
      background: #f4f4f4;
      margin: 0 20px;
      box-sizing: border-box;
    }
    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      .price1 {
        span:nth-child(1) {
          font-size: 29px;
          font-family: DIN Alternate, DIN Alternate-Bold;
          font-weight: 700;
          color: #333333;
          letter-spacing: 1.45px;
        }
        span:nth-child(2) {
          font-size: 53px;
          font-family: DIN Alternate, DIN Alternate-Bold;
          font-weight: 700;
          color: #333333;
          letter-spacing: 1.45px;
        }
        span:nth-child(3) {
          font-size: 29px;
          font-family: DIN Alternate, DIN Alternate-Bold;
          font-weight: 700;
          color: #333333;
          letter-spacing: 1.45px;
        }
      }
      .price2 {
        font-size: 26px;
        font-family: DIN, DIN-Regular;
        font-weight: 400;
        text-decoration: line-through;
        color: #999999;
        letter-spacing: 1.3px;
      }
    }
    .bottom {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      letter-spacing: 0.7px;
      margin: 16px 0 0;
      padding: 0 20px;
      box-sizing: border-box;
      img {
        width: 9px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }
}
.contain2 {
  width: 1400px;
  height: 217px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto 20px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.9px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #f4f4f4;
    margin: 20px 0;
  }
  .contain2_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      width: 325px;
      height: 112px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      img {
        width: 58px;
        height: 58px;
        margin-right: 20px;
        margin-left: 30px;
      }
      div {
        display: flex;
        flex-direction: column;
        span:nth-child(1) {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #333333;
          letter-spacing: 0.9px;
          margin-bottom: 6px;
        }
        span:nth-child(2) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          letter-spacing: 0.7px;
        }
      }
    }
  }
}
.btn {
  width: 366px;
  height: 65px;
  background: linear-gradient(90deg, #23c48c, #33da61);
  border-radius: 6px;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 0.9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}
</style>